<template>
  <auth-layout :img-url="imgUrl">
    <b-card-title title-tag="h2" class="font-weight-bold mb-1"> Yeni şifre oluşturun 🔒 </b-card-title>
    <b-card-text class="mb-2"> Şifrenizi değiştirmeniz için 1 saat süreniz bulunmaktadır. 1 saat sonunda tekrar bağlantı almanız gerekir. </b-card-text>
    <hr />
    <validation-observer ref="passwordchange">
      <app-input id="password" v-model="password" type="password" name="Şifre" label="Şifre:" placeholder="············" rules="required" @keyup.enter.native="changePassword" />
      <app-input
        v-model="passwordCheck"
        type="passwordCheck"
        name="Şifre Doğrulama"
        label="Şifre Doğrulama:"
        placeholder="············"
        rules="required|confirmed:password"
        @keyup.enter.native="changePassword"
      />
      <b-button variant="primary" type="submit" block @click="changePassword"> Oluştur </b-button>
    </validation-observer>
    <b-card-text class="text-center mt-2">
      <b-link :to="{ name: 'login' }"> <feather-icon icon="ChevronLeftIcon" /> Geri Dön </b-link>
    </b-card-text>
  </auth-layout>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import AuthLayout from "../AuthLayout.vue";

export default {
  components: {
    ValidationObserver,
    AuthLayout,
  },
  data() {
    return {
      password: null,
      passwordCheck: null,
    };
  },
  computed: {
    imgUrl() {
      return this.$store.state.appConfig.layout.skin === "dark"
        ? require("@/assets/images/pages/forgot-password-v2-dark.svg")
        : require("@/assets/images/pages/forgot-password-v2.svg");
    },
  },
  methods: {
    changePassword() {
      this.$validate(this.$refs.passwordchange, () => {
        this.$store.dispatch("changePassword", { password: this.password, token: this.$route.query?.token });
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
